import React from 'react';

import './App.css';
import logo from './img/logo192.png';

import HomePage from './components/HomePage';
import QuizHandler from './components/QuizHandler';

function App() {
  const currentUrl = window.location.pathname;

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo">
          <img src={logo} alt="Logo" />
          <h1>Lernbox Quiz</h1>
        </div>
      </header>
      <main>
        {currentUrl.startsWith('/q/') ? (
          <QuizHandler />
        ) : (
          <HomePage />
        )}
      </main>
    </div>
  );
}

export default App;