// QuizComponent.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRotateRight, faMagnifyingGlass, faArrowRightLong, faCheck, faXmark, faEllipsis, faShareFromSquare, faCircleInfo, faQrcode } from '@fortawesome/free-solid-svg-icons'
import './QuizComponent.css';

function QuizComponent(props) {
  const quiz = props.data;
  const questions = quiz.questions;

  const [amountCorrect, setAmountCorrect] = useState(0);

  const [selectedChoices, setSelectedChoices] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(questions[0]);
  const [result, setResult] = useState(null);
  const [running, setRunning] = useState(true);

  const currentIndexQuestion = questions.indexOf(currentQuestion);

  const handleChoiceChange = (event) => {
    const choice = event.target.value;
    if (event.target.checked) {
      setSelectedChoices([...selectedChoices, choice]);
    } else {
      setSelectedChoices(selectedChoices.filter((c) => c !== choice));
    }
  };

  const handleTriggerEvent = (id) => (event) => {
    const element = document.getElementById(id);
    element.click();
  };

  const handleCheck = () => {
    const correctChoices = currentQuestion.options
      .filter((option) => option.isCorrect)
      .map((option) => option.id);

    const isCorrect = selectedChoices.sort().toString() === correctChoices.sort().toString();

    if (isCorrect) {
      setAmountCorrect(amountCorrect + 1);
      setResult('Alles Richtig!');
    } else {
      setResult('Falsch!');
    }

    const selectedChoicesWithStatus = currentQuestion.options.map((choice) => ({
      ...choice,
      isCorrect: choice.isCorrect,
      isSelected: selectedChoices.includes(choice.id.toString()),
    }));

    const updatedChoices = selectedChoicesWithStatus.map((choice) => {
      if (choice.isCorrect && choice.isSelected) {
        return { ...choice, status: 'correct' };
      } else if (!choice.isCorrect && choice.isSelected) {
        return { ...choice, status: 'incorrect' };
      } else if (choice.isCorrect && !choice.isSelected) {
        return { ...choice, status: 'incorrect' };
      }
      return { ...choice, status: '' };
    });

    setCurrentQuestion((prev) => {
      const updatedQuestion = {
        ...prev,
        options: updatedChoices,
      };
    
      // Find the index of the current question in the questions array
      const index = currentIndexQuestion;
    
      // Replace the current question in the questions array with the updated question
      if (index !== -1) {
        questions[index] = updatedQuestion;
      }
    
      // Return the updated question to update the currentQuestion state
      return updatedQuestion;
    });
  };

  const nextQuestion = () => {
    setResult(null);
    setSelectedChoices([]);

    const index = questions.indexOf(currentQuestion);

    if (index + 1 < questions.length) {
      const nextQuestion = questions[index + 1];
      setCurrentQuestion(nextQuestion);
    } else {
      setRunning(false);
    }
  };



  const progress = ((questions.indexOf(currentQuestion) + 1) / questions.length) * 100;


  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    const copyText = window.location.href;
    const textArea = document.createElement('textarea');
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 6000);
  };

  const handleQRCode = () => {
    window.open(`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${window.location.href}`, '_blank');
  };


  return (
    <div className="QuizComponent">
      <header className="QuizComponent-header">
        <div className='menu-right'>
          <FontAwesomeIcon icon={faEllipsis} />
          <FontAwesomeIcon icon={faShareFromSquare} onClick={handleCopy} />
          <FontAwesomeIcon icon={faQrcode} onClick={handleQRCode} />
        </div>
      </header>
      <main>
        <h2 className="roboto-black f35">{quiz.title}</h2>

        {running && questions.length > 0 ? (
          <div>
            <div className="quiz-title">
              <h3 className="roboto-bold">{`Frage ${currentIndexQuestion +1}: ${currentQuestion.question}`}</h3>
            </div>

            <div className="progress-bar">
              <div className="progress-bar__fill" style={{ width: `${progress - 10}%` }}></div>
            </div>

            <form>
              {currentQuestion.options.map((choice) => (
                <div
                  className={`selectMulti ${choice.status}`} // Add the class based on the choice status
                  key={choice.id}
                  onClick={handleTriggerEvent(`choice-${choice.id}`)}
                >
                         {result !== null && (
                        choice.isCorrect ? <span className='span-result'> <FontAwesomeIcon icon={faCheck} /> </span> : <span className='span-result'> <FontAwesomeIcon icon={faXmark} /> </span>
                      )}
                  <label className="checkbox" onClick={handleTriggerEvent(`choice-${choice.id}`)}>
                    <input
                      className="checkbox"
                      type="checkbox"
                      id={`choice-${choice.id}`}
                      name="choices"
                      value={choice.id}
                      checked={selectedChoices.includes(choice.id.toString())}
                      onChange={handleChoiceChange}
                      disabled={result !== null}
                    />
                    <div className="checkbox__checkmark"></div>
                    <div className="checkbox__body roboto-regular">{choice.text}</div>
                  </label>
                </div>
              ))}
            </form>

            {result && <p>{result}</p>}

            <div className="quiz-buttons">
              <button className="btn roboto-regular f20" onClick={handleCheck} disabled={result !== null}><FontAwesomeIcon className='fa-icon-right' icon={faMagnifyingGlass} />
                Überprüfen
              </button>
              <button className="btn roboto-regular f20" onClick={nextQuestion} disabled={result == null}>
                Nächste Frage <FontAwesomeIcon className='fa-icon-left' icon={faArrowRightLong} />
              </button>
            </div>

          </div>
        ) : (
          <div>
            <p>Deine Ergebnisse:</p>
            <p className="f50">{amountCorrect} / {questions.length}</p>
            <h1>Quiz beendet</h1>
            <div className='center-child'>
              <button className="btn roboto-regular f20" onClick={() => window.location.reload()}><FontAwesomeIcon className='fa-icon-right' icon={faArrowRotateRight} />Quiz Neustarten </button>
            </div>
          </div>
        )}
        {copied && <span className='popover'><FontAwesomeIcon icon={faCircleInfo} /> Link zum Quiz kopiert!</span>}
      </main>
    </div>
  );
}

export default QuizComponent;
