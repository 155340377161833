import React from 'react';
import data from '../data/multiplechoice.json';
import QuizComponent from './QuizComponent';

function QuizHandler() {
  const quizzes = data.quizzes;

  const currentQuiz = window.location.pathname.split('/')[2] - 1;
  

  return (
    <div className="QuizHandler">
      <header className="QuizHandler-header"></header>
      <main>

            <QuizComponent data={quizzes[currentQuiz]} />

      </main>
    </div>
  );
}

export default QuizHandler;