import React from 'react';


function HomePage() {

  return (
    <div className="HomePage">
      <header className="HomePage-header"></header>
      <main>
        <h1>Startseite</h1>
        <p>
            Dies ist eine Quiz-App, die mit React gebaut wurde. Sie verwendet React Router zur Handhabung des Routings und React Context zur Verwaltung des Zustands.
        </p>
      </main>
    </div>
  );
}

// https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Client-side_JavaScript_frameworks/React_components
// https://www.freecodecamp.org/news/how-to-manage-state-in-a-react-app/

export default HomePage;